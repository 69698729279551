.header {
    z-index: 2;
    margin: auto;
    padding: 5px 10px;
    border-radius: 25px;
    opacity: 0.9;
    background: linear-gradient(to bottom, var(--header-background-color-start), var(--header-background-color-finish));
    box-shadow: 0 7px 30px rgba(0,0, 0, 0.7);
    top: 0;
    color: var(--header-text-color);
    position: relative;
    font-family: Neo-latina, sans-serif;
    width: 90%;
}



.downloadLink {
    text-decoration: none;
    width: 350px;
    color: white;
    font-size: 14px;
    padding: 10px 50px 10px 30px;
    background-color: var(--download-link-color);
    border-bottom-right-radius: 35px;
    border-bottom-left-radius: 35px;
    position: absolute;
    transition: all 0.3s ease-in-out;
    font-family: Neo-latina, sans-serif;
    background-image: url("../../assets/images/pdf.webp");
    background-size: 25px;
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: center;
    text-align: left;
    white-space: nowrap;
    outline: 3px solid white;
}

.scope {right: 33%;
    width: 320px;
    background-position-x: 80%;

}
.product{right: 15%;
    width: 300px;
text-align: center;

}

.downloadLink:hover {
    background-color: var(--download-link-color-hover);
    color: var(--text-color);
}
.desktopMenu {
    display: block;
}

/*----------mobile section-------*/
.mobileMenu {
    display: none;
}
/*----------mobile section---------*/

.logo {
    margin: 0;
    cursor: pointer;


    /*left: 1%;
    top: 50%;
    transform: translateY(-45%);*/
    transition: opacity 0.3s ease-in-out;
}

 .logo:hover  {
  opacity: 50%;
}

.logoImg {
    width: clamp(8em, 5vw, 12em);
    transform: translateY(10%);
    /*height: 20%;*/
}

.navWrap {
    /*width: 60%;*/
    margin: auto;
}
.navList {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    flex: 0;
    padding: 0;
    align-items: center;
}

.navDivider {
    height: 25px;
    border-right: 2px solid var(--header-border-color);
}

.navItem {
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 10px;
    transition: all .3s ease-in-out;
    white-space: nowrap;
    font-size: clamp(12px, 1vw, 16px);
    text-decoration: none;

}

.link {text-decoration: none;
color: var(--text-color);
}

.link:hover {
    color: white;
}

.navItem:hover .link {
    color: white;
}

.navItemLogo {
    white-space: nowrap;
}

.navItem:hover {
    color: white;
    background-color: #414d51;
}


.burger {
    /* left: 20px;*/
    width: 36px;
    height: 28px;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
    border: none;
    padding: 0;
    position: relative;
    transition: visibility .3s ease-in-out, opacity .3s ease-in-out, transform 3s ease-in-out;
    cursor: pointer;
    box-shadow: none
;

}

.burgerSpan {
    padding: 0;
    background-color:var(--text-color);
    height: 4px;
    width: 100%;
    transition: transform .3s ease-in-out, width .3s ease-in-out, opacity .2s ease-in-out;

}

.open.burgerSpan:first-child {

    transform: rotate(-45deg) translate(-9px, 9px);
}

.open .burgerSpan:nth-child(2) {
    opacity: 0;
}

.open .burgerSpan:last-child {
    transform: rotate(45deg) translate(-7px, -8px);
}

.burgerBtn {
    position: relative;
    z-index: 100;
}
.pdfMobWrap {
    display: none;
}
@media screen and (max-width: 1800px){
    .scope {
        width: 370px;
        background-position-x: 75%;
    }

}
@media screen and (max-width: 1600px){
    .scope {

        background-position-x: 70%;
    }

}
@media screen and (max-width: 1500px){
    .scope {

        background-position-x: 65%;
    }

}
@media screen and (max-width: 1320px) {

    .logoImg {
        width: clamp(10em, 10vw, 12em);
    }

    .navItem {
        font-size: 12px;
    }

    .downloadLink {
        font-size: 12px;
        width: 250px;
        /*right: 20%;
        font-size: 12px;
        width: 200px;
       background-position-x: 85%;*/
    }
    .scope {
        width: 300px;
        background-position-x: 72%
    }

}

@media screen and (max-width: 1200px) {


    .logoImg {
        width: clamp(5em, 10vw, 10em);
    }

.navItem {
    font-size: 10px;
}

.navItemLogo {
    transform: translateY(10%);
}

.downloadLink {
    font-size: 10px;
}
.scope {
    background-position-x: 65%;
}
}

@media screen and (max-width: 992px) {
    .header {
        width: 100%;
    }
    .mobileMenu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 20px;
        align-items: center;

    }
    .navItemLogo {
        display: none;
    }

    .link {
        color: white;
    }


    .pdfWrapDesktop {
        display: none;
    }

    .mobileLogo {
        width: 10em;
    }

    .mobileLogoImg {
        transform: translateY(10%);
        height: 85%;
        width: 100%;
    }
    .desktopMenu {
        display: none;
    }


    .scope {
        margin-bottom: 15px;
    }

    .navWrap {
        padding: 0 25px;
        width: 100%;
        height: 50vh;
        display: flex;
    }
    .navList {
padding: 0;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
    }

    .navItem:nth-last-child(n+3) {
        margin-bottom: 15px;
        border-bottom: 2px solid white;
    }

    .navItem {
        padding-bottom: 10px;
        text-align: center;
        border-radius: 0;
        font-family: Neo-latina, sans-serif;
        font-size: 16px;
    }

    .zvwLogoDark {
        width: 80px;
        display: block;
        /*margin-top: 25px;*/
        margin: 0 auto;
    }

    .navDivider {
        display: none;
    }

    .pdfMobWrap {
        display: block;
        padding: 0;
        border-bottom: none;
    }

    .mobLinkTextTop {
        font-family: Neo-latina, sans-serif;
        font-size: 18px;
    }

    .mobLinkTextBottom {
        font-weight: 600;
    }

    .mobLink {
        text-decoration: none;
        color: white;
        text-align: center;
    }

    .downloadLink {
        outline: none;
        display: block;
        width: 100%;
        color: white;
        font-size: 10px;
        padding: 10px 25px 10px 5px;
        background-color: var(--download-link-color);
        border-radius: 10px;
        position: relative;
        transition: all 0.3s ease-in-out;
        background-image: url("../../assets/images/pdf.webp");
        background-size: 20px;
        background-repeat: no-repeat;
        background-position-x: 95%;
        background-position-y: center;
        text-align: center;
        border-bottom: none;
        right: 0;
    }
    .header {
        width: 100%;
    }
}

@media screen and (max-width: 380px) {
    .header {
        padding: 1px 2px;
    }
    .burgerBtn {

    }
    .burger {
        width: 26px;
        height: 18px;
    }

    .burgerSpan {

        height: 2px;
    }

    .open.burgerSpan:first-child {

        transform: rotate(-45deg) translate(-4px, 5px);
    }

    .open .burgerSpan:last-child {
        transform: rotate(45deg) translate(-6px, -6px);
    }

    .mobileLogo {
        width: 7em;
    }
}

