.App {
  text-align: center;
  /*max-width: 1440px;*/
  margin: auto;
  padding: 0;
  overflow: hidden;
  /*background: linear-gradient(to top, #eff6fd , #ffffff);*/
  background: white;

}



.mainBlock {
  display: none;
}

.water {
  margin: auto;
 width: 100%;
  max-height: 35vh;
  height: 15vw;
  /*aspect-ratio: 1/5;-*/
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("./assets/images/water.jpg");
  /*background-position-y: 15%;*/
  /*background-attachment: fixed;*/

}

.apps {
  background-color: var(--block-background);
}


.mainBlock.display {
  /*max-width: 1440px;*/
  margin: auto;
  background-color: white;
  display: block;
}

.packagesBlock {
  display: none;
}

.packagesBlock.display {
  display: block;
}

.mainMenu {
  z-index: 1;
  position: fixed;
  width: 75%;
  top: 20px;
  max-width: 1440px;
  left: 50%;
  transform: translate(-50%, 0);
}

.language {
  position: fixed;
  top: -1px;
  right: 0;
  z-index: 1;



}

@media screen and (max-width: 1320px) {
  .mainMenu {
    width: 80%;
  }
}

@media screen and (max-width: 1024px) {
  .mainMenu {
    width: 85%;
  }
}

@media screen and (max-width: 992px) {
  .language {
    display: none;
  }
}

@media screen and (max-width: 576px){

  .App {

  }

}
