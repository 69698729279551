.errorMessage {
    margin-top: 150px;
    margin-bottom: 25px;
}

.img {
    width: 20%;
}

.errorLink {
    color: var(--text-bubbles);
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 0;
    text-decoration: none;
    transition: color .3s ease-in-out;
}

.errorLink:hover {
    color: var(--converse-text);
}

@media screen and (max-width: 768px) {
    .img {width: 50%}
}

@media screen and (max-width: 480px) {
   .img {
       width: 80%;
   }
}


