@import '~@csstools/normalize.css';
*, *::after, *::before {
  box-sizing: border-box;
}

@font-face {
  font-family: "Neo-latina";
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url("../public/fonts/neo_latina.woff2") format("woff2"),
  url("../public/fonts/neo_latina.woff") format("woff");
  font-display: swap;
}


:root {

  --text-color: #353434;
  --download-link-color: #3f494d;
  --download-link-color-hover: #8ca5b2;
  --header-text-color: #6c6d6f;
  --header-border-color: #c6caca;
  --header-background-color-start: #f2f2f2;
  --header-background-color-finish: #dadddd;
  --main-background-color: #fbfbfb;
  --bubbles-color: #212629;
  --text-bubbles: #6e609e;
  --text-footer: #cfcfce;
  --send-button-hover: #ef6324;
  --chat-button-color: #ef6324;
  --button-hero-shadow: #fbf7b1;
  --converse-text: #49abd7;
  --block-background: #f2f1f0;
  --packages-card-border: #d8d8d9;
  --MAXWIDTH: 1440px;
  --INNERWIDTH: 1320px;
}
html {
  background-color: var(--main-background-color);
  position: relative;
}

body {

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.MuiDrawer-paper {
  color: white !important;
  z-index: -1 !important;
  transition: background-color .3s ease-in-out;
  background-color: rgba(0,0,0, 0.7) !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fallback {
  position: fixed;
  z-index: 10000;
  height: 100vh;
  width: 100vw;
  margin: auto;
  background-color: rgba(0,0,0, 0.8);
  left: 0;
  top: 0;
}

.fbImg {
position: absolute;
  margin: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
}

.blocks-wrapper {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50% );
}
.cookie__buttons-wrapper {
  margin: auto;
}

.cookie__accept-button, .cookie__reject-button {
  border-radius: 25px !important;
}

@media screen and (max-width: 480px) {
  .cookie__accept-button, .cookie__reject-button, .cookie__container {
    font-size: 12px;
  }
}

@media screen and (max-width: 320px) {
  .cookie__content {
    margin: 15px 0 !important;
  }
}

@media screen and (max-width: 270px) {
   .cookie__content {
    margin: 15px 0 !important;
     flex:  1 !important;

  }

}



