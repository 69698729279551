.arrow {
    display: block;
    color: #fff;
}

.but {
    display: flex;
    flex-direction: column;
    padding: 0 5px 10px 10px;
    border-bottom-left-radius: 15px;
    background-color: #414d51;
    align-items: stretch;
    position: relative;
    height: 70px;
}

.zvwLogo {
    position: absolute;
    width: 40px;
    transition: opacity .2s ease-in-out;
}

.zvwLogo:hover {
    opacity: 0;
}

.logoColor:hover {
    opacity: 1;
}

.logoColor {
    transition: opacity .3s ease-in-out;
    width: 40px;
    position: absolute;
    opacity: 0;
}

.link {
    bottom: 5px;
    left: -5px;
    position: absolute;
    width: 35px;
    height: 35px;
}
.zvwLogoDark {
    display: none;
}


@media screen and (max-width: 1024px) {
    .arrow {
        display: none;
    }
    .link {
        left: -12px;
    }
}

@media screen and (max-width: 992px) {
    .but {
        border-radius: 10px;
        background-color: transparent;
        padding: 0;
        transform: none;
        align-self: center;
        flex-direction: row;
        height: 50px;
    }

    .zvwLogo, .logoColor, .link {
        display: none;
    }

    .zvwLogoDark {
        width: 40px;
        display: block;
        margin-left: 15px;
    }
}
